/**
 * Modal
 *
 * Usage:

	<a href="#'" data-modal="#my-modal" data-params="{"customName":"customvalue"}>Trigger modal</a>

	<section class="modal" id="my-modal">
		<a href="#" class="modal__close">&times;</a>
	</section>
 */

import $ from 'jquery';
import notif from 'components/notification';
import overlay from 'components/overlay';
import urlDecoder from 'helpers/url-parameter-decoder';

export default {
	init() {
		const trigger = '[data-modal]';
		const close = '.modal__close, [data-close-modal]';

		let modal = null;
		let params = {};

		$(document)
			// Close modal on any document click
			.on('click', (e) => {
				// Fix for Wistia videos inside of modals where clicking play closes the modal
				if ($(e.target).data('testid') && $(e.target).data('testid').indexOf('big-play-button') > -1) {
					return false;
				}

				/*
				 * Fix for `1Password - Password Manager` Chrome & Firefox extension injecting a customElement into the DOM that,
				 * when clicked, erroneously closes the modal.
				 */
				if ($(e.target).is('com-1password-button')) {
					return false;
				}

				if (!$(e.target).is('input')) {
					this.close();
				}
			})
			// Open up modal when clicking trigger
			.on('click', trigger, (e) => {
				const $target = $(e.currentTarget);
				const modalType = $target.data('modal');

				let remoteModalUrl;
				let remoteModalRequestType;

				e.preventDefault();
				e.stopPropagation();

				this.close();

				// Check to see if there are any data params on the trigger
				if ($target.data()) {
					params = $target.data();
				}

				// Check the value of the data-modal attr
				// to see if it contains "remote"

				if (modalType !== 'remote') {
					// If it's not a remote modal, go ahead and open it
					modal = $target.data('modal');
					this.open(modal, params);
				} else {
					// Otherwise, call out to the server
					// so we can get the markup to build the modal
					remoteModalUrl = $target.attr('href');
					remoteModalRequestType = $target.attr('data-type') ? $target.attr('data-type') : 'get';

					$.ajax({
						dataType: 'json',
						type: remoteModalRequestType,
						url: remoteModalUrl,
						success: (data) => {
							modal = this.remoteModalCallback(data, params);
						}
					});
				}
			})
			// Close when clicking "X"
			.on('click', close, (e) => {
				e.preventDefault();
				this.close(modal);
			})
			// Prevent clicks inside modal to close it
			.on('click', '.modal-container .modal__box, .modal-container.--full, [data-trigger-confirm]', (e) => {
				e.stopPropagation();
			})
			// Close on "esc" key
			.on('keydown', (e) => {
				if (e.keyCode === 27 && modal) {
					this.close();
				}
			});

		$('[data-auto-open]').each((index, item) => {
			this.open(`#${$(item).attr('id')}`, {}, false);
		});

		this.queryParameterCheck();
	},

	// Callback for when we request content for a remote modal
	remoteModalCallback(data, params) {
		let modalId, modalToReplace;

		if (data.result !== 'ok') {
			const message = this.messageToDisplay(data);
			// Throw a notification
			notif.show({
				message,
				type: 'error',
				float: true
			});
		} else {
			// Make sure no notifications are shown
			notif.hide();

			// Grab the ID so we can proceed like a normal modal
			modalId = $(data.content).attr('id');

			modalToReplace = $(data.content).attr('data-modal-replace') || $(`#${modalId}`);

			if (typeof modalToReplace !== 'undefined') {
				$(modalToReplace).remove();
			}

			// Only insert into DOM if one doesn't already exist
			if (!$(`#${modalId}`).length) {
				$(data.content).insertBefore('.overlay');
			}

			if (params.closeothers) {
				this.close();

				setTimeout(() => {
					// Then open it up
					this.open(`#${modalId}`, params, true);

					return `#${modalId}`;
				}, 200);
			} else {
				// Then open it up
				this.open(`#${modalId}`, params, true);

				return `#${modalId}`;
			}
		}
	},

	messageToDisplay(data) {
		if (typeof data.action !== 'undefined' && data.action.alert) {
			return data.action.alert;
		} else if (typeof data.alert !== 'undefined' && data.alert) {
			return data.alert;
		} else {
			return 'An error occurred. Please try again or contact support.';
		}
	},

	open(el, params, remote) {
		let firstInput;

		// Trigger custom event
		// passing along any custom params that were set on the trigger el
		$(document).trigger('modal:open', params);
		window.dispatchEvent(new Event('modal:open'));

		// Trigger specific custom event
		if (typeof el === 'string') {
			$(document).trigger(`modal.${el.substr(1)}:open`, params);
		}

		this.el = $(document).find(el);

		// If it's a remote modal, we have to add a tiny delay
		// in order to get the nice transition.
		// Otherwise, the open class gets added too quick
		if (remote) {
			setTimeout(() => {
				this.el.addClass('open');
			}, 1);
		} else {
			this.el.addClass('open');
		}

		// If it's not a full or menu modal...
		if (!this.el.hasClass('--full') && !this.el.hasClass('--menu')) {
			// pop the overlay for normal modals
			overlay.open('modal');
		} else if (this.el.hasClass('--menu')) {
			// pop the overlay for menu modals
			overlay.open('menu');
		} else if (this.el.hasClass('--full')) {
			// pop the overlay for full screen modals
			overlay.open('full_screen');
		}

		// Put cursor in first input if there's a form
		// ...but don't do it for modals on the migrations page
		if ($('body').data('controller') !== 'migration_requests') {
			firstInput = this.el.find('form input[type="text"]').first();

			if (firstInput.length) {
				firstInput.focus();
			}
		}
	},

	close(callback, closeOverlay = true) {
		if (overlay.isModalOpen()) {
			$(document).trigger('modal:close');
			window.dispatchEvent(new Event('modal:close'));
			$('.modal-container').removeClass('open');

			// Remove any form button spinner classes
			$('.modal-container').find('.button, button').attr('data-working', false);

			if (closeOverlay) {
				overlay.close();
			}

			if (typeof callback === 'function') {
				callback();
			}
		}
	},

	queryParameterCheck() {
		const modalId = urlDecoder('open_modal');
		const modalInDOM = document.querySelector(`#${modalId}-modal`);

		// Check if there is a URL param to open the collaborators modal
		if (modalId === 'collaborator') {
			// Open the collaborators modal
			this.open('#add-collaborator');
		} else {
			if (modalInDOM && typeof modalInDOM.dataset.openModal === 'undefined') {
				this.open(`#${modalId}-modal`);
			}
		}
	}
};
